import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, Globe, Layout, Type, UserCheck, BookOpen, Search, Target, FileText, CheckSquare, ChevronDown, } from 'lucide-react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Button = ({ icon: Icon, text, downloadLink, onClick }) => (
  <motion.a
    href={downloadLink}
    download={!!downloadLink}
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg flex items-center justify-center gap-2 shadow-lg shadow-blue-500/30 transition-shadow"
    whileHover={{ scale: 1.02, boxShadow: "0 8px 30px rgba(59, 130, 246, 0.5)" }}
    whileTap={{ scale: 0.98 }}
  >
    <Icon size={22} />
    <span className="text-lg">{text}</span>
  </motion.a>
);

// Improved FAQItem
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="mb-3"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <button
        className={`w-full flex items-center justify-between p-5 rounded-lg text-left transition-all duration-200
          ${isOpen ? 'bg-gray-700/80' : 'bg-gray-800/40 hover:bg-gray-700/60'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-medium text-gray-200 text-lg">{question}</h3>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-5 h-5 text-blue-400" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.2 }
              }
            }}
            exit={{ 
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 }
              }
            }}
            className="overflow-hidden"
          >
            <div className="px-5 py-4 text-gray-300 text-lg">
              <p>{answer}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const diagnosticSteps = [
  {
    title: "Cover Analysis",
    description: "We evaluate your cover's genre fit, visual appeal, typography, and thumbnail impact in the Amazon store.",
    icon: BookOpen
  },
  {
    title: "Description Review",
    description: "Analysis of your book description's hook, story promise, keywords, and ability to convert browsers to buyers.",
    icon: Search
  },
  {
    title: "Author Profile Assessment",
    description: "Review of your author brand, bio effectiveness, and platform presence across major retailers.",
    icon: Target
  },
  {
    title: "Market Position Check",
    description: "Evaluation of your book's category placement, pricing strategy, and comparison with successful competitors.",
    icon: FileText
  },
  {
    title: "Final Report & Action Items",
    description: "Detailed report with prioritized recommendations and specific steps for improving your book's market presence.",
    icon: CheckSquare
  }
];

const BookDoctor = () => {
  const [activeTab, setActiveTab] = useState('cover');
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Book Doctor Services - Kemah Bay Marketing</title>
        <meta name="description" content="Professional manuscript evaluation and consulting services. Get expert feedback to improve your book's potential." />
        <meta property="og:title" content="Book Doctor Services - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/BookDoctor" />
        <link rel="canonical" href="https://www.kemahbay.com/BookDoctor" />
      </Helmet>
      <main>
        {/* Hero Section with Video Background */}
        <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute inset-0 w-full h-full object-cover pointer-events-none"
          >
            <source src={process.env.PUBLIC_URL + '/resources/185092-874643408.mp4'} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-20 text-center flex flex-col items-center">
            <motion.div
              className="text-center max-w-4xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-6xl font-bold mb-6">Book Doctor</h1>
              <p className="text-2xl mb-8">Ever wonder why a book doesn't sell? Let our expert diagnostics uncover what's holding your book back.</p>
              <div className="flex justify-center space-x-4">
                <Button 
                  icon={FileText} 
                  text="Get Your Book Diagnosis" 
                  onClick={() => navigate('/Contact')}
                />
                
              </div>
            </motion.div>
          </div>
        </section>

        {/* Visual Problem Statement */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="space-y-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              {/* Top row with 3 items */}
              <div className="grid md:grid-cols-3 gap-8">
                <div className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <Layout className="w-12 h-12 mx-auto mb-4 text-red-400" />
                  <h3 className="text-xl font-semibold mb-3 text-center">Market Visibility Challenges</h3>
                  <p className="text-gray-300 text-base mb-4 text-left">Your book's potential might be hidden by current market conditions:</p>
                  <ul className="text-gray-300 text-base space-y-2 list-disc text-left ml-6">
                    <li>Genre trends are evolving faster than ever before</li>
                    <li>Cover designs that worked yesterday might not catch eyes today</li>
                    <li>Format choices could be limiting your book's accessibility</li>
                  </ul>
                </div>

                <div className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <Type className="w-12 h-12 mx-auto mb-4 text-red-400" />
                  <h3 className="text-xl font-semibold mb-3 text-center">Description Enhancement</h3>
                  <p className="text-gray-300 text-base mb-4 text-left">Your book's message might be facing modern marketplace challenges:</p>
                  <ul className="text-gray-300 text-base space-y-2 list-disc text-left ml-6">
                    <li>Today's readers need stronger emotional connections</li>
                    <li>Search algorithms require strategic keyword placement</li>
                    <li>Competition demands clearer value propositions</li>
                  </ul>
                </div>

                <div className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <UserCheck className="w-12 h-12 mx-auto mb-4 text-red-400" />
                  <h3 className="text-xl font-semibold mb-3 text-center">Author Platform Growth</h3>
                  <p className="text-gray-300 text-base mb-4 text-left">Your presence in today's digital landscape may need:</p>
                  <ul className="text-gray-300 text-base space-y-2 list-disc text-left ml-6">
                    <li>Modern strategies for genuine reader engagement</li>
                    <li>Personal brand storytelling that resonates</li>
                    <li>Platform optimization for maximum visibility</li>
                  </ul>
                </div>
              </div>

              {/* Bottom row with 2 centered items */}
              <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                <div className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <Target className="w-12 h-12 mx-auto mb-4 text-red-400" />
                  <h3 className="text-xl font-semibold mb-3 text-center">Audience Alignment</h3>
                  <p className="text-gray-300 text-base mb-4 text-left">Your reach might be affected by market evolution:</p>
                  <ul className="text-gray-300 text-base space-y-2 list-disc text-left ml-6">
                    <li>Reader preferences shift with cultural changes</li>
                    <li>New market segments emerge unexpectedly</li>
                    <li>Genre expectations continue to evolve</li>
                  </ul>
                </div>

                <div className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <Globe className="w-12 h-12 mx-auto mb-4 text-red-400" />
                  <h3 className="text-xl font-semibold mb-3 text-center">Market Positioning</h3>
                  <p className="text-gray-300 text-base mb-4 text-left">Your market presence might need adaptation to:</p>
                  <ul className="text-gray-300 text-base space-y-2 list-disc text-left ml-6">
                    <li>Current market pricing standards</li>
                    <li>Emerging distribution opportunities</li>
                    <li>New competitive landscape realities</li>
                  </ul>
                </div>
              </div>
            </motion.div>

            <motion.div 
              className="text-center mt-12 px-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <p className="text-2xl text-gray-300 max-w-2xl mx-auto">
                Today's publishing landscape is more complex than ever. Our Book Doctor service helps identify and overcome these common challenges, ensuring your book reaches its full potential in the market.
              </p>
            </motion.div>
          </div>
        </section>
        
        {/* Main Content with Interactive Elements */}
        <section className="bg-gray-800 py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="max-w-3xl mx-auto text-center mb-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold mb-6">Your Book's Success Depends on More Than Just Good Writing</h2>
              <p className="text-xl text-gray-300">Two heads are better than one, especially in today's competitive digital marketplace. Our Book Doctor service provides the professional evaluation your book needs to stand out.</p>
            </motion.div>

            <div className="max-w-4xl mx-auto bg-gray-700 rounded-lg p-8 mb-12">
              <div className="flex border-b border-gray-600 mb-6">
                {['cover', 'description', 'profile'].map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-2 ${activeTab === tab ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>
              
              <div className="min-h-[400px]">
                {activeTab === 'cover' && (
                  <motion.div 
                    className="flex flex-col items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="/resources/Presentation1.png"
                      alt="Cover analysis example"
                      className="w-1/2 h-auto mb-6"
                    />
                    <p className="text-gray-300 text-center max-w-2xl text-xl">
                      Our cover analysis examines genre fit, visual appeal, typography, and thumbnail impact. 
                      We ensure your book's first impression captures attention and communicates effectively to your target audience.
                    </p>
                  </motion.div>
                )}

                {activeTab === 'description' && (
                  <motion.div 
                    className="flex flex-col items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="text-white text-center max-w-2xl text-xl">
                    "As wave after wave of the Corona pandemic crisscrosses the planet, the economic and psychological impact of mandated lockdowns is pushing society toward the brink. When a new mutation, COVID-20, begins its deadly march, a population suffering from virus fatigue doesn’t heed the warnings, 
                    and the survival rate plummets. Hyper-inflation rears its ugly head, fueled by desperate government stimulus programs created to support a crumbling economy. Already profoundly divided politically, civilization succumbs to turmoil and anarchy. Best-selling author Joe Nobody’s new novel, 
                    COVID-21 follows the adventures of Clay Mantis, a typical teenager who struggles to survive the pandemic and its apocalyptic aftermath. After the government falls, only a handful of “company towns” bear any resemblance to what life was like before the downfall. Competition for resources 
                    is intense, as these walled bastions of civilization develop draconian policies and employ ruthless security forces to protect their way of life. With his family barely holding on, Clay discovers a new threat. For his family to survive, he will have to embark on a dangerous path that will test all his courage and skills."- COVID-21 by Joe Nobody
                    </p>
                    <div>
                    <p className="text-gray-300 text-center max-w-2xl py-8 text-xl">
                      A compelling book description is crucial for converting browsers to buyers. 
                      We analyze your blurb's hook, story promise, and keyword optimization to maximize its effectiveness.
                    </p>
                    </div>
                  
                  </motion.div>
                )}

                {activeTab === 'profile' && (
                  <motion.div 
                    className="flex flex-col items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="/resources/AuthorProfile.png"
                      alt="Author profile example"
                      className="w-1/2 h-auto mb-6 rounded-lg"
                    />
                    <p className="text-gray-300 text-center max-w-2xl text-xl">
                      Your author profiles on social media and as a website helps builds trust and connection with readers. 
                      We help optimize your bio, author photo, and platform presence to strengthen your author brand. We can also 
                      create independent websites! 
                    </p>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Service Process with Visual Timeline */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Diagnostic Process
            </motion.h2>
            <div className="relative">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-1 h-full bg-blue-500"></div>
              </div>
              {diagnosticSteps.map((step, index) => (
                <motion.div
                  key={index}
                  className="relative z-10 flex items-center mb-8"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 rounded-full p-2 mr-4">
                    <div className="bg-white rounded-full p-2">
                      <step.icon className="w-6 h-6 text-blue-500" />
                    </div>
                  </div>
                  <div className="bg-gray-700 rounded-lg p-6 flex-grow">
                    <h3 className="text-xl font-semibold mb-2 text-blue-400">{step.title}</h3>
                    <p className="text-gray-300 text-lg">{step.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Before & After Showcase */}
        <section className="bg-gray-800 py-10">
          <div className="container mx-auto px-2">
            <h2 className="text-3xl font-bold mb-8 text-center">Real Results</h2>
            
            {/* Added introductory text */}
            <div className="max-w-2xl mx-auto text-center mb-8 text-2xl">
              <p className="text-gray-300">Years ago, We were presented with a great dystopian novel that had a strong blurb, good editing, and a strong hook at the beginning. Sales however, were lacking, and it didn't take us long to identify a handful of reasons why.</p>
            </div>
            
            <div className="grid grid-cols-2 gap-8">
              {/* Column Headers */}
              <div className="p-4 rounded-lg">
                <h3 className="text-3xl font-semibold text-red-400 text-center">Before</h3>
              </div>
              <div className="p-4 rounded-lg">
                <h3 className="text-3xl font-semibold text-green-400 text-center">After</h3>
              </div>
              
              {/* Row 1 - Cover Example */}
              <div className="p-4 bg-gray-700 rounded-lg">
                <div className="flex flex-col items-center h-[500px]">
                  <div className=" h-[400px] mb-4 flex items-center justify-center">
                    <img
                      src="/resources/beforecoverexample.jpg"
                      alt="Before cover example"
                      className="max-h-full w-auto object-contain rounded-lg"
                    />
                  </div>
                  <p className="text-gray-300 text-lg">Our Book Doctor team recommended a professional cover, suggested the author republish the tome under a different genre, and made minor modifications to the book's blurb.</p>
                </div>
              </div>
              <div className="p-4 bg-gray-700 rounded-lg">
                <div className="flex flex-col items-center h-[500px]">
                  <div className="w-1/2 h-[400px] mb-4 flex items-center justify-center">
                    <img
                      src="/resources/aftercoverexample.jpg"
                      alt="After cover example"
                      className="max-h-full w-3/4 object-contain rounded-lg"
                    />
                  </div>
                  <p className="text-gray-300 text-lg">To date, <span className="italic">Holding Their Own</span> by Joe Nobody has sold over 400,000 copies worldwide. Since its fantastic turn-around, the author has used our service for each of his 40+ novels.</p>                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing and Packages */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="bg-gray-800 rounded-lg p-8 max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold mb-6 text-center">Investment in Your Success</h2>
              <div className="text-center mb-8">
                <p className="text-5xl font-bold text-blue-400 mb-4">$250</p>
                <p className="text-gray-300 mb-4">per title (series discounts available)</p>
                <ul className="text-left space-y-4 mb-8">
                  <li className="flex items-center">
                    <CheckCircle className="w-6 h-6 text-green-400 mr-3" />
                    <span>3-day turnaround time</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="w-6 h-6 text-green-400 mr-3" />
                    <span>Evaluation by 3+ industry professionals</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="w-6 h-6 text-green-400 mr-3" />
                    <span>Comprehensive written report</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="w-6 h-6 text-green-400 mr-3" />
                    <span>Action items prioritized by impact</span>
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className=" bg-slate-700 py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto text-lg">
              <FAQItem 
                question="How long does it take to evaluate my book?"
                answer="Our goal is to provide feedback within five business days. This can vary depending on our workload and the time of the year, but our normal process takes days, not weeks."
              />
              <FAQItem 
                question="What do you evaluate?"
                answer="We try to put ourselves in the place of the reader, so all public-facing elements of the book and author are evaluated. Obviously, the cover and blurb are a top priority. We also take the time to preview the book, review the author's website, and gauge the author's social media presence, and the genre used for the title."
              />
              <FAQItem 
                question="Who evaluates my book?"
                answer="We gather a team of experts for every title we review. This includes professional editors, proofreaders with proven track records, and graphic arts professionals. A serious attempt is made to include team members who are age appropriate and buy books in the same genre."
              />
              <FAQItem 
                question="Will using KBM's Book Doctor guarantee my book will sell?"
                answer="No, unfortunately, we cannot guarantee sales of any title. What we can promise is that your book's sales won't be hurt by below-standard elements that lead to a reader's decision on what to read next. With Book Doctor, you can rest assured that you present the best possible optics on your hard work and dedicated effort."
              />
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="bg-slate-900 py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h2 className="text-4xl font-bold mb-6">Ready to Boost Your Book's Success?</h2>
              <p className="text-xl mb-8 max-w-2xl mx-auto">Don't let your book's potential go unrealized. Get professional insights that can transform your book's market presence.</p>
              <div className="flex justify-center">
                <Button icon={FileText} text="Schedule Your Book Doctor Consultation" onClick={() => navigate('/Contact')} />
              </div>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default BookDoctor;