import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HelpCircle, ChevronDown,  } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="mb-4 bg-gray-800 rounded-lg shadow-md overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <button
        className="w-full flex items-center justify-between p-4 bg-gray-700 text-left hover:bg-gray-600 transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-semibold text-white">{question}</h3>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="text-blue-400" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.2 }
              }
            }}
            exit={{ 
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 }
              }
            }}
            className="overflow-hidden"
          >
            <div className="p-4 bg-gray-800">
              <p className="text-gray-300">{answer}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQSection = ({ title, faqs }) => (
  <div className="mb-12">
    <h2 className="text-2xl font-bold text-blue-400 mb-6">{title}</h2>
    {faqs.map((faq, index) => (
      <FAQItem key={index} question={faq.question} answer={faq.answer} />
    ))}
  </div>
);

const FAQPage = () => {
  const audioBookFaqs = [
    {
      question: "What if ACX makes its services available in my country of residence?",
      answer: "Your existing books with KBM must remain in our account for five years after being published. This is an ACX requirement. After that time, we can delist the title and then you can upload the book to your own ACX account."
    },
    {
      question: "Is there any limit to the number of titles or author names with Kemah Bay's service?",
      answer: "No, there is no limit. You can publish as many titles and use as many author names as you wish through our service."
    },
    {
      question: "What if I don't like KBM's service, or if there are problems?",
      answer: "We do our best to provide the highest standards of customer service. We also work closely with ACX to ensure a smooth partnership that benefits all parties. Between the author, ACX, and Kemah Bay, we have yet to encounter an issue that can't be resolved to everyone's satisfaction."
    },
    {
      question: "Will KBM help with narrator selection or Audiobook marketing as part of this service?",
      answer: "No. You must deliver acceptable audio files to us. Our offering is strictly a distribution for pay agreement. However, we can provide guidance on best practices for narrator selection and marketing if needed."
    },
    {
      question: "I am a non-USA narrator and want to offer my services via ACX. Can I sign up?",
      answer: "Unfortunately, no. Our agreement with ACX is only for authors/publishers at this time. We'll update our services if this changes in the future."
    },
    {
      question: "What if ACX doesn't reimburse me correctly for royalties owed?",
      answer: "We will act as the author's agent if there are royalty disputes or other support issues with ACX. While it has never occurred to date, if a payment is lost in the mail, or goes missing, we will work with you to resolve the situation promptly."
    },
    {
      question: "Does KBM withhold taxes from the royalty payments?",
      answer: "No. Nor does ACX withhold any US taxes. One of the reasons why we work exclusively through PayPal is that they deal with much of the paperwork regarding US tax requirements and filings. Each individual author is responsible for filing taxes in the appropriate country of residence."
    },
    {
      question: "How long does it take to publish an Audiobook?",
      answer: "The process typically takes 4-8 weeks. Once you deliver the audio files to us, we upload them to ACX. They perform a quality control check of the book and audio levels, which takes an average of 30 days. After ACX has approved the title, it takes another 3-5 days before the Audiobook is live on Amazon, Audible, and other platforms."
    }
  ];

  const bookDoctorFaqs = [
    {
      question: "How long does it take to evaluate my book?",
      answer: "Our goal is to provide feedback within five business days. This can vary depending on our workload and the time of the year, but our normal process takes days, not weeks."
    },
    {
      question: "What do you evaluate?",
      answer: "We try to put ourselves in the place of the reader, so all public-facing elements of the book and author are evaluated. Obviously, the cover and blurb are a top priority. We also take the time to read the \"look inside,\" or preview, review the author's website and social media presence, and the genre used for the title."
    },
    {
      question: "Who evaluates my book?",
      answer: "We gather a team of experts for every title we review. This includes professional editors, proofreaders with proven track records, and graphic arts professionals. A serious attempt is made to include team members who are age appropriate and buy books in the same genre."
    },
    {
      question: "Will using Kemah's Book Doctor guarantee my book will sell?",
      answer: "No, unfortunately, we cannot guarantee sales of any title. What we can promise is that your book's sales won't be hurt by below-standard elements that lead to a reader's decision on what to read next. With Book Doctor, you can rest assured that you present the best possible optics on your hard work and dedicated effort."
    }
  ];

  const marketingFaqs = [
    {
      question: "What marketing services do you offer?",
      answer: "Our marketing services section is coming soon. Please check back for updates."
    }
  ];

  const editingFaqs = [
    {
      question: "How long does proofreading normally take?",
      answer: "Of course, this depends on the length of the book and the number of errors we encounter. As a general rule, our team can process 7500 words per day."
    },
    {
      question: "How many proofreaders do you have on staff?",
      answer: "We have over a dozen proofreaders who, above all else, love to read. They encompass numerous genres and have been trained how to report issues in a manor that makes the potential problem clear to the author."
    },
    {
      question: "Does KBM use any technology to line edit my book?",
      answer: "Yes, we use several different professional editing and proofreading software packages combined with classically trained editors to ensure a consistant and precise product."
    },
    {
      question: "How long does it take Kemah to edit my book?",
      answer: "Of course, this depends on the length of the title and how many edits are required. As a general rule, our team can process 6700 words per day for a line edit, 10,000 words per day for a story edit."
    },
    {
      question: "What format do you need the title in?",
      answer: "Microsoft Word is our preferred format, as all of our readers and editors use Word on a daily basis. We can also accept plain text, Google documents, and a few other less-common formats."
    },
    {
      question: "What standard does Kemah use for grammar?",
      answer: "We subscribe to the Chicago Manual of Style for both fiction and non-fiction. Our staff receive updates throughout the year."
    },
    
  ];

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Frequently Asked Questions - Kemah Bay Marketing</title>
        <meta name="description" content="Find answers to common questions about our publishing, editing, and marketing services." />
        <meta property="og:title" content="FAQ - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/FAQ" />
        <link rel="canonical" href="https://www.kemahbay.com/FAQ" />
      </Helmet>
      <main className="container mx-auto px-4 py-12">
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <HelpCircle className="w-16 h-16 text-blue-400 mx-auto mb-4" />
          <h1 className="text-4xl font-bold">Frequently Asked Questions</h1>
          <p className="text-xl text-gray-400 mt-2">All Services</p>
        </motion.div>
        
        <div className="max-w-3xl mx-auto">
          <FAQSection title="Audiobook Distribution" faqs={audioBookFaqs} />
          <FAQSection title="Book Doctor" faqs={bookDoctorFaqs} />
          <FAQSection title="Marketing" faqs={marketingFaqs} />
          <FAQSection title="Editing & Proofreading" faqs={editingFaqs} />
        </div>
      </main>
    </div>
  );
};

export default FAQPage;