import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Pen, Edit, Type, ChevronDown, Megaphone, Headphones, Ambulance, Star, BookOpen, Users, Swords, Heart, Rocket, Glasses, Skull, Baby, Calculator,  ScrollText } from 'lucide-react';
import Footer from './Footer'
import SEOHeader from "./SEOHeader"
import { Link } from 'react-router-dom';

const PublishingLandingPage = () => {
  const [formData, setFormData] = useState({
    manuscriptReady: '',
    publishedBefore: '',
    bookType: '',
    name: '',
    email: '',
    services: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const CircularCarousel = () => {
    const images = [
      '/resources/Slideshow/714jqMewEXL._SL1500_.jpg',
      '/resources/Slideshow/71Mt9s6twNL._SL1500_.jpg',
      '/resources/Slideshow/61MTzI8XyRL._SL1360_.jpg',
      '/resources/Slideshow/61yotgV0mML._SL1360_.jpg',
      '/resources/Slideshow/81DqKedkp2L._SL1360_.jpg',
      '/resources/Slideshow/81evI4hnp+L._SL1500_.jpg',
      '/resources/Slideshow/71FUOhDcjqL._SL1500_.jpg',
    ];
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
  
    useEffect(() => {
      if (!isHovered) {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2500);
        return () => clearInterval(interval);
      }
    }, [images.length, isHovered]);
  
    const getPositionStyle = (index) => {
      const totalImages = images.length;
      const radius = 500;
      const baseAngle = (2 * Math.PI * index) / totalImages;
      const rotationOffset = (2 * Math.PI * currentIndex) / totalImages;
      const angle = baseAngle - rotationOffset;
  
      // Calculate 3D position
      const x = radius * Math.sin(angle);
      const z = radius * Math.cos(angle);
      
      // Calculate scale based on z position
      const maxScale = 1;
      const minScale = 0.5;
      const scale = minScale + (maxScale - minScale) * ((z + radius) / (2 * radius));
      
      // Calculate opacity based on z position
      const opacity = 0.4 + 0.6 * ((z + radius) / (2 * radius));
  
      // Calculate rotation - each cover rotates to face the viewer
      const rotateY = (angle * 180) / Math.PI;
  
      return {
        transform: `translate3d(${x}px, 0, ${z}px) rotateY(${rotateY}deg) scale(${scale})`,
        opacity,
        zIndex: Math.round(z + radius),
      };
    };
  
    return (
      <div className="min-h-[700px] w-full bg-white/5">
        <h1 className="pt-8 text-center text-6xl font-bold text-white">Featured Books</h1>
        <div 
          className="flex h-[600px] w-full items-center justify-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="relative h-[500px] w-[325px] perspective-1000">
            <div className="absolute -translate-x-1/2 -translate-y-1/2 preserve-3d">
              {images.map((src, index) => (
                <motion.div
                  key={index}
                  className="absolute h-64 w-80 -translate-x-1/2 -translate-y-1/2 cursor-pointer preserve-3d"
                  initial={false}
                  animate={getPositionStyle(index)}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut"
                  }}
                  onClick={() => setCurrentIndex(index)}
                >
                  <img
                    src={src}
                    alt=""
                    className="rounded-lg object-cover shadow-xl backface-visible"
                    draggable={false}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const genres = [
    { name: 'Action & Adventure', icon: Swords, description: 'Epic journeys and thrilling adventures' },
    { name: 'Comics & Graphic Novels', icon: BookOpen, description: 'Visual storytelling at its finest' },
    { name: 'Romance', icon: Heart, description: 'Love stories that captivate hearts' },
    { name: 'Science Fiction', icon: Rocket, description: 'Exploring new worlds and possibilities' },
    { name: 'Mystery', icon: Glasses, description: 'Intriguing puzzles and suspense' },
    { name: 'Horror', icon: Skull, description: 'Spine-chilling tales of terror' },
    { name: "Children's Books", icon: Baby, description: 'Inspiring young minds' },
    { name: 'Non-Fiction', icon: Calculator, description: 'Real-world knowledge and insights' },
    { name: 'Literary Fiction', icon: ScrollText, description: 'Thoughtful and compelling narratives' },
    
  ];

  const stats = [
    { 
      icon: BookOpen,
      label: "Books Published", 
      value: "600+",
      description: "Successfully published works across all genres"
    },
    { 
      icon: Star,
      label: "Reviews Generated", 
      value: "300,000+",
      description: "Genuine reader reviews and ratings"
    },
    { 
      icon: Users,
      label: "Books Sold", 
      value: "1 Million +",
      description: "Writers trust us with their creative works"
    },
    { 
      icon: Megaphone,
      label: "Revenue Generated", 
      value: "$7M+",
      description: "For our author partners worldwide"
    }
  ];

  const services = [
    { icon: Pen, title: "Drafting", description: "We help authors bring their manuscripts and drafts to life! Contact us for a free services quote!", link: "/Contact" },
    { icon: Edit, title: "Editing", description: "Professional editors with decades of experience. We can edit for flow, grammar, and story content!", link: "/EditingProofreading" },
    { icon: Type, title: "Proofreading", description: "We are experts at curating stylistic options to your books theme and genre.", link: "/EditingProofreading" },
    { icon: Megaphone, title: "Marketing", description: "We market your books with surgical precision, and we know what works regardless of budget.", link: "/Marketing" },
    { icon: Headphones, title: "Audiobook Distribution", description: "We have published hundreds of audiobooks, let us help you explore various platforms, find the perfect voice, and cover for your book.", link: "/AudioBookDistribution" },
    { icon: Ambulance, title: "Book Doctor Consultations", description: "Is your book not meeting expectations? We perform comprehensive analytics-based content and analytics consulting!", link: "/BookDoctor" },
  ];

  const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <motion.div 
        className="mb-3"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <button
          className={`w-full flex items-center justify-between p-4 rounded-lg text-left transition-all duration-200
            ${isOpen ? 'bg-gray-700/80' : 'bg-gray-800/40 hover:bg-gray-700/60'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="font-medium text-whtie text-lg">{question}</h3>
          <motion.div
            initial={false}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className="w-5 h-5 text-blue-400" />
          </motion.div>
        </button>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ 
                height: "auto",
                opacity: 1,
                transition: {
                  height: { duration: 0.2 },
                  opacity: { duration: 0.2 }
                }
              }}
              exit={{ 
                height: 0,
                opacity: 0,
                transition: {
                  height: { duration: 0.2 },
                  opacity: { duration: 0.1 }
                }
              }}
              className="overflow-hidden"
            >
              <div className="p-4 text-white">
                <p>{answer}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        services: checked
          ? [...prev.services, value]
          : prev.services.filter(service => service !== value)
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch('https://kemahbay.netlify.app/.netlify/functions/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
      
      if (data.success) {
        alert(data.message);
        setFormData({
          manuscriptReady: '',
          publishedBefore: '',
          bookType: '',
          name: '',
          email: '',
          services: []
        });
      } else {
        alert(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <SEOHeader />

      <main className="relative">
        <section className="hero-section relative min-h-[90vh]">
            {/* Background Image with Overlay */}
            <div 
              className="absolute inset-0 bg-cover bg-center"
              style={{ 
                backgroundImage: "url('/resources/table.jpg')",
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            >
              <div className="absolute inset-0 bg-slate-800/30"></div>
            </div>

            {/* Content Container */}
            <div className="relative container mx-auto py-12 flex flex-col md:flex-row items-center gap-8">
              {/* Left Content */}
              <motion.div
                className="md:w-1/2 bg-gray-800/50 backdrop-blur-sm rounded-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="text-5xl font-bold mb-6 text-white">The Independent Author's Best Friend</h1>
                <div className="space-y-4 text-white">
                  <p className="text-lg">We are a boutique publishing and marketing firm offering a catalog of services for independent authors, regardless of genre or length of work.</p>
                  <p className="text-lg">Our team of experts have helped hundreds of writers at all experience levels achieve their goals.</p>
                  <p className="text-lg">With clients from Houston to Mumbai, we pride ourselves shaping works that can reach an audience regardless of where they are in the world.</p>
                  <p className="text-lg">Check out our <a href="https://us.amazon.com/s?k=Kemah+Bay+Marketing%2C+LLC" className="text-blue-400 hover:text-blue-300">Amazon Store</a> to see some of the books we have helped bring to market.</p>
                  <p className="text-4xl font-bold mt-6">Publish with us today!</p>
                </div>
              </motion.div>

              {/* Right Form */}
              <motion.div
                className="md:w-1/2 bg-gray-800/50 backdrop-blur-sm rounded-lg p-8"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <form onSubmit={handleSubmit} className="space-y-6 text-white">
                <div>
                  <label className="block mb-2 text-left ">Do you have a manuscript ready?</label>
                  <select 
                    name="manuscriptReady" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-left">Have you published before?</label>
                  <select 
                    name="publishedBefore" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="bookType" className="block mb-2 text-left">What genre of book do you plan on publishing?</label>
                  <input
                    type="text"
                    id="bookType"
                    name="bookType"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter your book genre"
                  />
                </div>

                <div>
                  <label htmlFor="name" className="block mb-2 text-left">What is your name?</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your first and last name here"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block mb-2 text-left">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your email address here"
                    required
                  />
                </div>

                <div>
                  <label className="block mb-2 text-left">What Services Are You Looking For?</label>
                  <div className="grid grid-cols-2 gap-4">
                    {['Self Publishing', 'Editing', 'Cover Consultation', 'Printing', 'Interior Formatting', 'International Kindle Publishing'].map((service) => (
                      <label key={service} className="flex items-center space-x-2 text-left">
                        <input
                          type="checkbox"
                          name="services"
                          value={service}
                          onChange={handleInputChange}
                          className="rounded border-gray-400 text-blue-500 focus:ring-blue-500"
                        />
                        <span>{service}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </form>
            </motion.div>
          
          </div>
        </section>

        <section className="bg-white/5 py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8 text-white">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                className="text-center p-6 rounded-xl bg-white/5 backdrop-blur-lg border border-white/10"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <stat.icon className="w-12 h-12 mx-auto mb-4 text-blue-400" />
                <p className="text-4xl font-bold text-blue-400 mb-2">{stat.value}</p>
                <p className="text-lg font-semibold mb-2">{stat.label}</p>
                <p className="text-lg text-gray-400">{stat.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      

      <section className="bg-gray-900 py-20 ">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-10 text-center text-white"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Our Product and Service Offerings
          </motion.h2>
          <div className="flex flex-col md:flex-row text-white">
            <div className="md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 md:mb-0">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="p-6 bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 hover:bg-white/10 transition-colors"
              >
                <service.icon className="w-12 h-12 text-blue-400 mb-4" />
                <h3 className="text-xl font-bold mb-3">{service.title}</h3>
                <p className="text-gray-300 text-lg leading-relaxed mb-4">{service.description}</p>
                <Link to={service.link} className="text-blue-400 hover:text-blue-300">
                  Learn more →
                </Link>
              </motion.div>
              ))}
            </div>
            <div className="md:w-1/3 md:pl-8">
              <motion.img
                src="/resources/71Mt9s6twNL._SL1500_.jpg"
                alt="Book Publishing Process"
                className="rounded-lg shadow-lg"
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              />
            </div>
          </div>
        </div>
      </section>

      <CircularCarousel/>

        <section className="relative bg-gray-900 py-20 overflow-hidden">
          <div className="container mx-auto px-4 relative z-10">
            <motion.h2
              className="bg-gray-800 rounded-lg p-6 mx-auto max-w-xl text-4xl font-bold mb-12 text-center text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Genres We Publish
            </motion.h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {genres.map((genre, index) => (
                <motion.div
                  key={index}
                  className="group relative bg-gray-800 rounded-lg p-6"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-start space-x-4">
                    <genre.icon className="w-8 h-8 text-blue-400 group-hover:text-white" />
                    <div>
                      <h3 className="text-xl font-semibold text-white mb-2">{genre.name}</h3>
                      <p className="text-gray-300 group-hover:text-white/90">{genre.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
            
            <motion.div
              className="text-center mt-12"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.5 }}
              viewport={{ once: true }}
            >
              <p className="bg-gray-800 rounded-lg p-6 mx-auto max-w-xl text-lg text-gray-300">
                Don't see your genre? We work with all types of content!
                <br />
                <span className="text-blue-400 hover:text-blue-300 cursor-pointer transition-colors">
                  Contact us to discuss your specific needs.
                </span>
              </p>
            </motion.div>
          </div>
        </section>       

        <section className="relative min-h-[90vh]">
            {/* Background Image with Overlay */}
            <div 
              className="absolute inset-0 bg-cover"
              style={{ 
                backgroundImage: "url('/resources/workspace.jpg')",
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            >
              <div className="absolute inset-0 bg-gray-900/50"></div>
            </div>

            <div className="container mx-auto px-4 py-12 flex flex-col rounded-xl md:flex-row items-start gap-8 relative z-10">
              {/* FAQ Section */}
              <motion.div
                className="md:w-1/2 bg-gray-800/20 backdrop-blur-md rounded-xl p-8 text-white"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <h2 className="text-3xl font-bold mb-8 text-white">General Questions</h2>
                {[
                  {
                    question: "How much does it cost to publish a book?",
                    answer: "Costs vary based on services needed. We offer flexible packages starting from basic publishing to full-service marketing campaigns."
                  },
                  {
                    question: "How long does the publishing process take?",
                    answer: "Typically 2-4 months from manuscript to publication, depending on editing needs and chosen services."
                  },
                  {
                    question: "Do I retain rights to my book?",
                    answer: "Yes, you maintain 100% rights to your work. We help publish and market while you keep full ownership."
                  },
                  {
                    question: "Can you help with marketing after publication?",
                    answer: "Absolutely! We offer comprehensive marketing services including social media, Amazon ads, and promotional campaigns."
                  },
                  {
                    question: "Do I need professional editing?",
                    answer: "While not mandatory, we strongly recommend professional editing to ensure your book meets industry standards."
                  },
                  {
                    question: "What about book cover design?",
                    answer: "We offer professional cover design services with multiple revisions to ensure your book stands out."
                  },
                  {
                    question: "Can you help with international distribution?",
                    answer: "Yes, we handle worldwide distribution through Amazon, Barnes & Noble, and other major platforms."
                  },
                  {
                    question: "What formats will my book be available in?",
                    answer: "We publish in paperback, hardcover, eBook, and can assist with audiobook production."
                  },
                  {
                    question: "How do royalties work?",
                    answer: "You receive up to 70% of eBook sales and varying percentages for print based on distribution channels."
                  },
                  {
                    question: "Do you offer ongoing support?",
                    answer: "Yes, we provide continuous support and consultation throughout your publishing journey and beyond."
                  }
                ].map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </motion.div>

              {/* Form Section - Your existing form code */}
              <motion.div
                className="md:w-1/2 bg-gray-800/20 backdrop-blur-md rounded-xl p-8"
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <h2 className="text-3xl font-bold mb-4 text-white">Get Discovered!</h2>
                <p className="text-xl mb-8 text-white">Let us help the world discover your work! Are you ready?</p>
                
                <form onSubmit={handleSubmit} className="text-white space-y-6">
                <div>
                  <label className="block mb-2 text-left">Do you have a manuscript ready?</label>
                  <select 
                    name="manuscriptReady" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-left">Have you published before?</label>
                  <select 
                    name="publishedBefore" 
                    onChange={handleInputChange} 
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="bookType" className="block mb-2 text-left">What genre of book do you plan on publishing?</label>
                  <input
                    type="text"
                    id="bookType"
                    name="bookType"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter your book genre"
                  />
                </div>

                <div>
                  <label htmlFor="name" className="block mb-2 text-left">What is your name?</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your first and last name here"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block mb-2 text-left">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded bg-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Please enter your email address here"
                    required
                  />
                </div>

                <div>
                  <label className="block mb-2 text-left">What Services Are You Looking For?</label>
                  <div className="grid grid-cols-2 gap-4">
                    {['Self Publishing', 'Editing', 'Cover Consultation', 'Printing', 'Interior Formatting', 'International Kindle Publishing'].map((service) => (
                      <label key={service} className="flex items-center space-x-2 text-left">
                        <input
                          type="checkbox"
                          name="services"
                          value={service}
                          onChange={handleInputChange}
                          className="rounded border-gray-400 text-blue-500 focus:ring-blue-500"
                        />
                        <span>{service}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div className="text-left">
                  <button 
                    type="submit" 
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded transition-colors"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </section>
      </main>
      <Footer />      
    </div>
  );
};

export default PublishingLandingPage;