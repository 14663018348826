import React, { useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Edit, FileText, Book, Check, X, Clock, DollarSign, Search, CheckCircle, ChevronDown } from 'lucide-react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Button = ({ icon: Icon, text, onClick, secondary = false }) => (
  <motion.button
    onClick={onClick}
    className={`${secondary ? 'bg-gray-600 hover:bg-gray-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded flex items-center justify-center`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="mr-2" size={20} />
    <span>{text}</span>
  </motion.button>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="mb-3"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <button
        className={`w-full flex items-center justify-between p-4 rounded-lg text-left transition-all duration-200
          ${isOpen ? 'bg-gray-700/80' : 'bg-gray-800/40 hover:bg-gray-700/60'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-medium text-whtie text-lg">{question}</h3>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-5 h-5 text-blue-400" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.2 }
              }
            }}
            exit={{ 
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 }
              }
            }}
            className="overflow-hidden"
          >
            <div className="p-4 text-white">
              <p>{answer}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const InfoBox = ({ title, children }) => (
  <motion.div
    className="bg-gray-800 rounded-lg shadow-lg p-6"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <h3 className="text-xl font-semibold mb-4 text-blue-400">{title}</h3>
    {children}
  </motion.div>
);

const EditingServicesPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Helmet>
        <title>Professional Editing & Proofreading Services - Kemah Bay Marketing</title>
        <meta name="description" content="Expert book editing and proofreading services. Polish your manuscript with our professional editing team." />
        <meta property="og:title" content="Editing & Proofreading Services - Kemah Bay Marketing" />
        <meta property="og:url" content="https://www.kemahbay.com/EditingProofreading" />
        <link rel="canonical" href="https://www.kemahbay.com/EditingProofreading" />
      </Helmet>
      <main>
        <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-75 z-10"></div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute inset-0 w-full h-full object-cover pointer-events-none"
          >
            <source src={process.env.PUBLIC_URL + '/resources/editing.mp4'} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-20 text-center flex flex-col items-center">
            <motion.h1
              className="text-5xl font-bold mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Polish Your Masterpiece
            </motion.h1>
            <motion.p
              className="text-xl mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Expert editing and proofreading to refine your words and elevate your story.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >           
              <Button icon={Edit} text="Get A Quote" onClick={() => navigate('/EditingProofReadingQuote')} />
            </motion.div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              The Impact of Professional Editing & Proofreading
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex flex-col items-center">
                <h3 className="text-2xl font-semibold mb-4">Without Professional Services</h3>
                <ul className="space-y-2 text-lg">
                  {[
                    "Typos and grammatical errors",
                    "Inconsistent plot and character development",
                    "Pacing issues",
                    "Unclear narrative voice",
                    "Potential negative reviews",
                    "Mismatched file versions",
                    "Overlooked minor errors"
                  ].map((item, index) => (
                    <li key={index} className="flex items-center">
                      <X className="w-5 h-5 mr-2 text-red-500" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col items-center">
                <h3 className="text-2xl font-semibold mb-4">With Our Services</h3>
                <ul className="space-y-2 text-lg">
                  {[
                    "Polished, professional prose",
                    "Coherent plot and well-developed characters",
                    "Engaging pacing and flow",
                    "Strong, consistent narrative voice",
                    "Increased chance of positive reviews",
                    "Thorough final review",
                    "Detailed quality assurance"
                  ].map((item, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="w-5 h-5 mr-2 text-green-500" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Services
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                {
                  title: "Line Editing",
                  description: "Refine your prose, enhance clarity, and improve flow.",
                  price: "$.005 per word",
                  icon: Edit
                },
                {
                  title: "Developmental Editing",
                  description: "Address plot, character, and structural issues.",
                  price: "$.01 per word",
                  icon: Book
                },
                {
                  title: "Copyediting",
                  description: "Polish to catch any remaining errors.",
                  price: "$.005 per word",
                  icon: FileText
                },
                {
                  title: "Proofreading",
                  description: "Final detailed review for professional presentation.",
                  price: "$.003 per word",
                  icon: CheckCircle
                }
              ].map((service, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-700 p-6 rounded-lg shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  viewport={{ once: true }}
                >
                  <service.icon className="w-12 h-12 mb-4 text-blue-500" />
                  <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                  <p className="mb-4">{service.description}</p>
                  <p className="font-bold text-blue-400">{service.price}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              What We Accept & How We Work
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <InfoBox title="Manuscript Requirements">
                <ul className="list-disc list-inside space-y-2 text-gray-300 text-lg">
                  <li>Any genre, including non-fiction and steamy prose</li>
                  <li>Any length of work up to 2MB in file size</li>
                  <li>English manuscripts</li>
                  <li>Microsoft Word (2003 or higher) formatted files</li>
                </ul>
              </InfoBox>
              <InfoBox title="Service Timeline">
                <ul className="space-y-2 text-gray-300 text-lg">
                  <li className="flex items-center">
                    <Clock className="w-5 h-5 mr-2 text-blue-500" /> 
                    Availability - typically begin within 5 business days
                  </li>
                  <li className="flex items-center">
                    <Clock className="w-5 h-5 mr-2 text-blue-500" /> 
                    Estimated duration - 3 business days per 20K words
                  </li>
                  <li className="flex items-center">
                    <DollarSign className="w-5 h-5 mr-2 text-blue-500" /> 
                    Custom quotes based on service type and word count
                  </li>
                </ul>
              </InfoBox>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 py-10">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-5 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Editing Process
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center text-lg">
              <motion.img
                src="/resources/editing_diagram.png" 
                alt="Editing process diagram"
                className="rounded-lg w-4/5 h-4/5"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              />
              <div>
                {[
                  { title: "Initial Assessment", description: "We review your manuscript to determine the best approach." },
                  { title: "First Pass", description: "Our editors make their initial round of edits and suggestions." },
                  { title: "Author Review", description: "You review our edits and provide feedback or clarifications." },
                  { title: "Final Polish", description: "We make final adjustments based on your input." },
                  { title: "Quality Check", description: "A senior editor reviews the final manuscript for quality assurance." },
                  { title: "Proofreading", description: "Optional final, thorough review for any remaining errors." }
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    className="mb-4"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                    viewport={{ once: true }}
                  >
                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                    <p>{step.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl font-bold mb-10 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Our Deliverables
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                className="bg-gray-700 p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">Editing Deliverables</h3>
                <ul className="space-y-2 text-gray-300 text-lg">
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                    <span>Comprehensive editorial letter outlining major feedback and recommendations</span>
                  </li>
                  
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                    <span>Follow-up consultation to discuss changes and address questions</span>
                  </li>
                </ul>
              </motion.div>
              
              <motion.div
                className="bg-gray-700 p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-400">Proofreading Deliverables</h3>
                <ul className="space-y-2 text-gray-300 text-lg">
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                    <span>Manuscript with highlighted grammar issues and explanations</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                    <span>Separate document noting plot confusion, missing elements, or general questions</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                    <span>Phone/email support as required</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto text-lg">
              <FAQItem 
                question="How long does proofreading normally take?"
                answer="Of course, this depends on the length of the book and the number of errors we encounter. As a general rule, our team can process 7500 words per day."
              />
              <FAQItem 
                question="How many proofreaders do you have on staff?"
                answer="We have over a dozen proofreaders who, above all else, love to read. They encompass numerous genres and have been trained how to report issues in a manner that makes the potential problem clear to the author."
              />
              <FAQItem 
                question="Does KBM use any technology to line edit my book?"
                answer="Yes, we use several different professional editing and proofreading software packages combined with classically trained editors to ensure a consistant and precise product."
              />
              <FAQItem 
                question="How long does it take KBM to edit my book?"
                answer="Of course, this depends on the length of the title and how many edits are required. As a general rule, our team can process 6700 words per day for a line edit, 10,000 words per day for a story edit."
              />
              <FAQItem 
                question="What format do you need for editing?"
                answer="Microsoft Word is our preferred format, as all of our readers and editors use Word on a daily basis."
              />
              <FAQItem 
                question="What standard does KBM use for grammar?"
                answer="We subscribe to the Chicago Manual of Style."
              />
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              className="bg-gray-800 rounded-lg shadow-lg p-8 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold mb-6">Why Choose Our Services?</h2>
              <p className="text-lg mb-8">
                Whether you need comprehensive editing or a final proofread, our specialized team of editors and proofreaders 
                possesses the rare capability to process nearly every word, ensuring your manuscript reaches its full potential.
                Regardless of your manuscript's age or stage, a quality review is always a worthwhile investment in your work's success.
              </p>
              <motion.h2
              className="text-3xl font-bold mb-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              Ready to Elevate Your Manuscript?
            </motion.h2>
              <div className="flex justify-center space-x-4">
              <Button icon={Edit} text="Get A Quote" onClick={() => navigate('/EditingProofReadingQuote')} />
              <Button icon={Search} text="View FAQs" onClick={() => navigate('/FAQ')} secondary />
              </div>
            </motion.div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default EditingServicesPage;